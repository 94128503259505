import { Component, inject, input } from "@angular/core"
import { faUser } from "@fortawesome/pro-solid-svg-icons"
import { DataAccessFirebaseAuthService } from "data-access/firebase-auth"
import { RouterLink, RouterLinkActive } from "@angular/router"
import { UiIconComponent } from "ui/icon"

@Component({
  imports: [
    UiIconComponent,
    RouterLink,
    RouterLinkActive,
  ],
  standalone: true,
  template: `
    @if (buttonClasses()) {
      @if (!loggedIn()) {
        <button
          class="btn"
          [class]="buttonClasses()"
          routerLink="/login"
        >
          <lib-ui-icon [icon]="faUser"/>
        </button>
      }
      @if (loggedIn()) {
        <button
          class="btn"
          [class]="buttonClasses()"
          routerLink="/login"
          routerLinkActive="btn-primary"
        >
          @if (profileImagePhotoURL()) {
            <div style="width: 22px">
              <img
                style="clip-path: circle(50%)"
                alt=""
                [src]="profileImagePhotoURL()"
              />
            </div>
          } @else {
            <lib-ui-icon [icon]="faUser"/>
          }
        </button>
      }
    } @else {
      @if (!loggedIn()) {
        <a routerLink="/login">
          <lib-ui-icon [icon]="faUser"/>
        </a>
      }
      @if (loggedIn()) {
        <a routerLink="/login">
          @if (profileImagePhotoURL()) {
            <div style="width: 22px">
              <img
                style="clip-path: circle(50%)"
                alt=""
                [src]="profileImagePhotoURL()"
              />
            </div>
          } @else {
            <lib-ui-icon [icon]="faUser"/>
          }
        </a>
      }
    }
  `,
  selector: "e2e-user-avatar",
})
export class UserAvatarComponent {
  private authDataAccessService = inject(DataAccessFirebaseAuthService)

  buttonClasses = input("")

  loggedIn = this.authDataAccessService.loggedIn
  profileImagePhotoURL = this.authDataAccessService.afUserPhotoUrl

  protected readonly faUser = faUser
}
