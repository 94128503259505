import { Component, input } from "@angular/core"
import { AnimationProp, FaIconComponent, IconDefinition } from "@fortawesome/angular-fontawesome"

@Component({
  imports: [
    FaIconComponent
  ],
  standalone: true,
  template: `
    <div class="inline-block">
      <fa-icon [icon]="icon()" [animation]="animation()"/>
    </div>
  `,
  selector: "lib-ui-icon",
})
export class UiIconComponent {
  icon = input.required<IconDefinition>()
  animation = input<AnimationProp | undefined>(undefined)
}
