import { Component, inject } from "@angular/core"
import { faAngleRight, faHouseChimney } from "@fortawesome/pro-solid-svg-icons"
import { RouterLink } from "@angular/router"
import { TitleCasePipe } from "@angular/common"
import { UiIconComponent } from "ui/icon"
import { GroupsService } from "./products/groups/groups.service"

@Component({
  imports: [
    RouterLink,
    UiIconComponent,
    TitleCasePipe,
  ],
  standalone: true,
  template: `
    <nav
      role="tablist"
      aria-label="Breadcrumb"
      class="row"
    >
      <a
        routerLink="/products"
        rel="noopener noreferrer"
        role="listitem"
      >
        <lib-ui-icon [icon]="faHouseChimney"/>
      </a>
      @for (breadcrumb of breadcrumbs(); track breadcrumb.label; let last = $last) {
        <lib-ui-icon role="presentation" [icon]="faAngleRight" style="font-size: 24px"/>
        @if (!last) {
          <a
            [routerLink]="breadcrumb.routerLink"
            rel="noopener noreferrer"
            role="listitem"
          >
            {{ breadcrumb.label | titlecase }}
          </a>
        }
        @if (last) {
          <div class="bg-primary p-2 text-primary-content">
            {{ breadcrumb.label | titlecase }}
          </div>
        }
      }
    </nav>
  `,
  selector: "e2e-breadcrumb",
})
export class BreadcrumbComponent {
  private groupService = inject(GroupsService)

  // home = signal({ icon: "pi pi-home", routerLink: "/products" })

  breadcrumbs = this.groupService.breadcrumbs

  protected readonly faAngleRight = faAngleRight
  protected readonly faHouseChimney = faHouseChimney
}
