import { Component, inject, OnInit } from "@angular/core"
import { RouterModule } from "@angular/router"
import { ContentService } from "./components/import/content.service"
import { FirestoreReadService } from "./components/import/firestore-read.service"
import { SiteTopBarComponent } from "./components/site-top-bar.component"
import { SiteFooterComponent } from "./components/site-footer.component"

@Component({
  imports: [
    RouterModule,
    SiteTopBarComponent,
    SiteFooterComponent,
  ],
  providers: [],
  standalone: true,
  template: `
    <div class="min-h-dvh column gap-0">
      <div class="bg-base-100" style="width: 100%; position: fixed; z-index: 4">
        <e2e-site-top-bar/>
      </div>
      <div class="pt-20 pb-2.5 px-2.5">
        <!--
              <div class="prose p-10" style="max-width: 100%; text-align: center">
                <h1>
                  Duggan Farrier Supply
                </h1>
              </div>
        -->
        <router-outlet></router-outlet>
      </div>
      <div class="flex-grow"></div>
      <e2e-site-footer/>
    </div>

  `,
  selector: "e2e-root",
})
export class AppComponent implements OnInit {
  private contentService = inject(ContentService)
  private firestoreReadService = inject(FirestoreReadService)

  ngOnInit() {
    /**
     * instead of loading data from firestore,
     * let's try getting data from storage
     *
     * POC
     * get spreadsheetFileName from docs("content/content.spreadsheetFilename")
     * this will require processing and storing it client-side
     * localStorage.setItem("spreadsheetFilename", JSON.stringify(ALL_DATA))
     */
    this.contentService.getLocalStorageRawData()
    this.firestoreReadService.subscribeToJsonFileRef()
  }

}
