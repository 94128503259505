import { Component } from "@angular/core"

@Component({
  imports: [
  ],
  standalone: true,
  template: `
    <div class="column-center">
      <h2 class="center text-2xl">
        Upcoming Events
      </h2>
      MN School of Horseshoeing
      <br>
      Professional Farrier 12- and 24-week Courses
      <br>
      August 26 – November 15, 2024 *Limited Space Left*
      <br>
      Ramsey, MN
    </div>
  `,
  selector: "e2e-events",
})
export class EventsComponent {

}
