import { Component, inject, output } from "@angular/core"
import { CheckoutService } from "./checkout.service"
import { faCartShopping } from "@fortawesome/pro-regular-svg-icons"
import { CheckoutComponent } from "./checkout.component"
import { UiDialogComponent } from "ui/dialog"
import { CartComponent } from "./cart.component"

@Component({
  imports: [
    CheckoutComponent,
    UiDialogComponent,
    CartComponent,
  ],
  standalone: true,
  template: `
    <lib-ui-dialog
      alignTitle="center"
      (cancelAndClose)="close.emit()"
    >
      <div ngProjectAs="title">
        <div>
          Cart
        </div>
      </div>

      <div ngProjectAs="content">
        <div class="column">
          <e2e-cart/>
          <div class="row-center">

            <e2e-checkout/>

            <button
              class="btn btn-success btn-sm"
              [disabled]="!checkoutLineItems().length"
              (click)="emptyCart()"
            >
              empty cart
            </button>

          </div>
        </div>

      </div>
    </lib-ui-dialog>

  `,
  selector: "e2e-cart-prompt",
})
export class CartPromptComponent {
  private cartService = inject(CheckoutService)

  checkoutLineItems = this.cartService.checkoutLineItems
  close = output()

  emptyCart() {
    this.cartService.emptyCart()
  }

  protected readonly faCartShopping = faCartShopping
}
