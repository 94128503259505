import { computed, inject, Injectable, signal } from "@angular/core"
import { Stripe } from "stripe"
import { Group, Item } from "../import/content.service"
import { toObservable } from "@angular/core/rxjs-interop"
import { DataAccessFirebaseAuthService } from "data-access/firebase-auth"
import { catchError, map, switchMap } from "rxjs/operators"
import { from, of } from "rxjs"
import { doc, Firestore, getDoc } from "@angular/fire/firestore"

export interface CustomerDoc {
  email: string
  stripeId: string
  stripeLink: string
}

export interface CheckoutSessionLineItem extends Stripe.Checkout.SessionCreateParams.LineItem {
  price_data: {
    currency: string
    product_data: {
      name: string
      description: string // displays in checkout, include unit measurement (each, per pair, per box, etc)
      // images: string[]
      metadata: {
        itemId: string
        itemSku: string
      }
    },
    unit_amount: number
  },
  adjustable_quantity: {
    enabled: boolean
    minimum: number
    maximum: number
  },
  quantity: number
}

@Injectable({
  providedIn: "root",
})
export class CheckoutService {
  private authDataAccessService = inject(DataAccessFirebaseAuthService)
  private firestore = inject(Firestore)

  afUser = this.authDataAccessService.afUser

  private _checkoutLineItems = signal<CheckoutSessionLineItem[]>([])
  checkoutLineItems = computed(() => this._checkoutLineItems())

  customerId = signal<string | undefined>(undefined)

  constructor() {
    this._checkoutLineItems.set(JSON.parse(localStorage.getItem("checkoutLineItems") || "[]"))

    /**
     * watch user login an set customerId when acquired
     */
    toObservable(this.afUser)
      .pipe(
        switchMap(user => {
          if (user?.uid) {
            return from(getDoc(doc(this.firestore, "customers", user.uid)))
              .pipe(
                map(snapshot => {
                  if (snapshot.exists()) {
                    const firestoreCustomer = snapshot.data() as CustomerDoc
                    return firestoreCustomer.stripeId
                  }
                  return undefined
                }),
                catchError(() => of(undefined)),
              )
          }
          return of(undefined)
        }),
      )
      .subscribe(id => this.customerId.set(id))

  }

  initializeLineItems() {
    /**
     * use localStorage values to set line items after content items have been populated, only once after initial page load
     */
    /*
        const checkoutLineItemsString = localStorage.getItem("checkoutLineItems")
        const actions: Action[] = []
        if (checkoutLineItemsString && items) {
          const checkoutLineItemsFromStorage = JSON.parse(checkoutLineItemsString) as CheckoutSessionLineItem[]
          const checkoutLineItems: CheckoutSessionLineItem[] = []
          checkoutLineItemsFromStorage.map(checkoutLineItem => {
            const lineItem = items[checkoutLineItem.price_data.product_data.metadata.itemSku]
            if (lineItem) {
              checkoutLineItems.push({
                ...checkoutLineItem,
                price_data: {
                  ...checkoutLineItem.price_data,
                  product_data: {
                    ...checkoutLineItem.price_data.product_data,
                    description: lineItem.description || checkoutLineItem.price_data.product_data.description,
                    name: lineItem.title,
                  },
                  unit_amount: lineItem.retail * 100,
                },
              })
            }
          })
          if (checkoutLineItems.length) {
            actions.push(CartActions.setLineItems({ checkoutLineItems }))
          }
        }
    */

  }

  emptyCart() {
    this._checkoutLineItems.set([])
    localStorage.removeItem("checkoutLineItems")
  }

  removeItem(lineItemIndex: number, lineItems: CheckoutSessionLineItem[]): void {
    if (lineItemIndex < 0 || lineItemIndex >= lineItems.length) {
      console.error("Index out of bounds")
      return
    }

    // Create a new array with the item at the specified index removed
    const updatedLineItems = [
      ...lineItems.slice(0, lineItemIndex),
      ...lineItems.slice(lineItemIndex + 1),
    ]

    // Update the checkoutLineItems with the new array
    this._checkoutLineItems.set(updatedLineItems)
  }

  add(selectedItem: Item, group: Group, quantity: number) {
    const checkoutSessionLineItem = {
      price_data: {
        currency: "usd",
        product_data: {
          name: selectedItem.title,
          description: selectedItem.description || "no description", // include unit measurement, eg. each, per pair, per box, etc
          // images: [selectedItem.images[0] || group.images[0] || []] as string[],
          metadata: {
            itemId: selectedItem.sku,
            itemSku: selectedItem.sku,
          },
        },
        unit_amount: Math.round(selectedItem.retail * 100),
      },
      adjustable_quantity: {
        enabled: true,
        minimum: 0,
        maximum: 9999,
      },
      quantity,
    }

    const checkoutLineItems = [...this._checkoutLineItems()] as CheckoutSessionLineItem[]
    const existingLineItemIndex = checkoutLineItems
      .findIndex(checkoutLineItem =>
        checkoutLineItem.price_data.product_data.metadata.itemSku === selectedItem.sku)

    if (existingLineItemIndex === -1) { // add new line item
      checkoutLineItems.push(checkoutSessionLineItem)
    }
    if (existingLineItemIndex !== -1) { // add quantity to existing line item
      checkoutLineItems[existingLineItemIndex].quantity = checkoutLineItems[existingLineItemIndex].quantity + checkoutSessionLineItem.quantity
    }

    this._checkoutLineItems.set(checkoutLineItems)
    localStorage.setItem("checkoutLineItems", JSON.stringify(checkoutLineItems))

  }
}
